import styled from "@emotion/styled";
import { graphql } from "gatsby";
import Image from 'gatsby-image';
import React from "react";
import SpacerComponent from "../components/contents/spacer";
// import AnimatedHeadline from '../components/animated.headline/animated.headline';
import FooterComponent from '../components/footer/footer';
import { Container } from '../components/grid/grid.component';
import { H2, H5 } from '../components/grid/typography.component';
import Header from '../components/header/header';
import Modules from "../components/modules/modules";
import { HeroImage, HeroText, ImageHeroWrapper } from '../styles/shared';

class ProductTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const product = data.product.data;
    return (
      // <TransitionState>
      //   {({ transitionStatus, exit, enter }) => {
      //     // console.log('exit object is', exit)
      //     // console.log('enter object is', enter)

      //     return (
      <PageWrapper>
        {/* <Header /> */}
        <ImageHeroWrapper>
          <div className="clipper">
            <Header color="#fff" />
          </div>
          <HeroText>
            <H5>Case study</H5>
            <H2>{product.title.text}</H2>
            <H5>by {product.by && product.by.document.map(company => (<span key={company.data.name}>{company.data.name}</span>))}</H5>
          </HeroText>
          <HeroImage>
            {product.mainimage.localFile && <Image fluid={product.mainimage.localFile.childImageSharp.fluid} />}
          </HeroImage>
        </ImageHeroWrapper>
        <SpacerComponent />
        <Container>
          {product.body && product.body.map((data, i) =>
            <Modules key={`product-module-${i}`} __typename={data.__typename} data={data} alldata={product} />
          )}
          <SpacerComponent />
          {/* {product.by && product.by.document.map(designer => (
            <DesignerIntroComponent key={designer.id} {...designer.data} />
          ))} */}
        </Container>
        {/* <AnimatedHeadline headline={product.title} /> */}
        <SpacerComponent />
        <FooterComponent />
      </PageWrapper>
      //     )
      //   }}
      // </TransitionState>

    )
  }
}
export default ProductTemplate;









export const query = graphql`
  query($slug: String!, $lang: String!) {
    product: prismicProduct(uid: { eq: $slug },lang: { eq: $lang }) {
      id
      data{
        title {
          text
        }
        by {
          document{
            ...prismicCompanyFragment
          }
        }
        mainimage {
          ...prismicProductDataMainimageFragment
        }

        height
        width
        depth
        technical_drawing_horizontal {
          ...prismicProductDataTechnicalDrawingHorizontalFragment
        }
        technical_drawing_vertical {
          ...prismicProductDataTechnicalDrawingVerticalFragment
        }
        materialscount
        materials{
          area 
          areamaterials
          array
        }
        colorscount
        colors{
          area 
          areacolors
          array
        }
        body {
          __typename
          ... on PrismicProductBodyIntro{
            
            id
            primary{
              text {
                text
                html
              }
            }
          }

          ... on PrismicProductBodyMediafullwidth {
            id
            
            items{
              image {
                ...prismicProductBodyMediafullwidthItemsImageFragment
              }
              caption {
                html
                text
              }
            }
          }
          ... on PrismicProductBodyDesignerIntro {
            id
            
          }
          
          ... on PrismicProductBodySpecs{
            id
            
          }
          
          ... on PrismicProductBodySpacer{
            id
            
          }


          
        }
      }
    }
  }
`


const PageWrapper = styled.div`

  .tl-wrapper-status--entering &{
    opacity: 0;
  }
  .tl-wrapper-status--entered &{
    opacity: 1;
  }
`;



